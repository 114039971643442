import React, { useEffect, useState } from 'react';
import { getTokenFromLS } from "../../lib/helpers"; 
import './Logs.styles.scss';
import { Link } from 'react-router-dom';
import CircularProgressIndicator from '../../components/CircularProgress/CircularProgress.component';

const UserLogsPage = () => {
  const [userLogs, setUserLogs] = useState([]);
  const [logsEmpty, setLogsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    try {
      // Fetch logs from the API
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-user-logs`, {
        headers: {
          'Content-Type': 'application/json',
          token: getTokenFromLS(),
        },
      });
      const data = await response.json();
      if (data.length === 0) {
        setLogsEmpty(true);
      }
      const sortedLogs = sortLogsByDateTime(data);
      setUserLogs(sortedLogs);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user logs:', error);
      setLogsEmpty(true);
      setIsLoading(false);
    }
  };
  
  const sortLogsByDateTime = (logs) => {
    return logs.sort((a, b) => {
      const dateTimeA = new Date(a.updatedAt);
      const dateTimeB = new Date(b.updatedAt);
      return dateTimeB - dateTimeA;
    });
  };

  const getUserTimezoneDate = (extractedDate) => {
    var date = new Date(extractedDate);
    var userOffset = new Date().getTimezoneOffset();
    userOffset = -userOffset;
    var userOffsetMillisecs = userOffset * 60 * 1000;
    var userDate = new Date(date.getTime() + userOffsetMillisecs);
    var formattedDate = userDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    var formattedTime = userDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
    return formattedDate + ' ' + formattedTime;
  };
  const ShowCollectionDetails = ({log}) =>{
    const totalOriginalSize = log.meta.reduce((acc, metaItem) => acc + metaItem.originalImagesSize, 0);
    const totalOptimizedSize = log.meta.reduce((acc, metaItem) => acc + metaItem.optimizedImagesSize, 0);
    const totalSavedSize = totalOriginalSize - totalOptimizedSize;
    return (
    <div className='meta-item' style={{gap:'4.2vw'}}>
    <p className='data-text' style={{width:'6.17vw'}}>
      {log.meta[0].collectionName}
    </p>
    <p className='data-text' style={{width: '6.17vw'}}>
      {covertToKB(totalOriginalSize)}
    </p>
    <p className='data-text add-padding-left' style={{width:'6.17vw'}}>
      {covertToKB(totalOptimizedSize)}
    </p>
    <p className='data-text add-padding-left' style={{width:'6.17vw'}}>
      {covertToKB(totalSavedSize)}
    </p>
    {/* Assuming that 'metaItem.totalSavedBytes' actually represents the difference between original and optimized sizes */}
    <p className='add-margin-right detail-class' style={{width:'6.17vw'}}>
      <Link to={`/logs/${log.jobId}`} className="details-link">
        Details
      </Link>
    </p>
  </div>)
  }
  const groupLogsByDateAndCollection = () => {
    const groupedLogs = {};
    userLogs.forEach((log) => {
      const key = log.updatedAt.slice(0, -8); 
      if (!log.meta) {
        return;
      }
      const collectionName = Array.isArray(log.meta) ? log.meta[0].collectionName : log.meta.collectionName;
      const displayKey = log.optimizationId || collectionName;
      if (!groupedLogs[key]) {
        groupedLogs[key] = {};
      }
      if (!groupedLogs[key][displayKey]) {
        groupedLogs[key][displayKey] = [];
      }
      groupedLogs[key][displayKey].push(log);
    });
    return groupedLogs;
  };

  const covertToKB = (size) =>{
    return (size/1024).toFixed(2)+'KB'
  }
  const covertToMB = (size) =>{
    return (size / 1024).toFixed(2) + 'MB';
  }
  const groupedLogs = groupLogsByDateAndCollection();

  return (
    <div className='main-box'>
      {isLoading ? (
        <div className='loader-div'>
          <CircularProgressIndicator value={null} className='check-box-btn' style={{color:"black", marginTop:'5vh'}}/>
        </div>
      ) : logsEmpty ? (
        <div className='empty'>
          <div className='empty-header'>No Optimizations Done Here.</div>
          <div className='empty-text'>
            You can <a href='/pixie'>optimize your Webflow sites.</a>
          </div>
        </div>
      ) : (
        <div>
          <div className='heading-wrapp'>
            {/* ... Breadcrumbs or other heading content ... */}
          </div>
          {Object.keys(groupedLogs).map((dateTimeKey, groupIndex) => (
            <div key={groupIndex}>
              <div className='header-wrapper'>
              {Object.values(groupedLogs[dateTimeKey]).some(logs => logs[0].optimizationId) ? null : (
                <h2 className='collection-name'>
                  {getUserTimezoneDate(dateTimeKey)}
                </h2>
              )}
              </div>
              <div className="white-box">
                {Object.keys(groupedLogs[dateTimeKey]).map((collectionName, logIndex) => (
                  <>
                    <div className="header-wrapper" style={{marginBottom: groupedLogs[dateTimeKey][collectionName][0].optimizationId ? '0vh' : '1.33vh'}}>
                      <h3 className="collection-name">{collectionName}</h3>
                    </div>
                    <p className='optimization-type'>{groupedLogs[dateTimeKey][collectionName][0].optimizationId ? groupedLogs[dateTimeKey][collectionName][0].type + ' @ ' + getUserTimezoneDate(dateTimeKey): null}</p>
                    <div className='light-grey-box header-box' style={{gap: groupedLogs[dateTimeKey][collectionName][0].optimizationId ? '4.5vw' : '5.2vw'}}>
                      <p>{groupedLogs[dateTimeKey][collectionName][0].optimizationId ? 'Collection Name' : 'Field Name'}</p>
                      <p>Original Size</p>
                      <p>Optimized Size</p>
                      <p>Saved Size</p>
                      <p>Action</p>
                    </div>
                    {groupedLogs[dateTimeKey][collectionName].map((log, logIndex) => (
                      <div className='grp-data' key={logIndex}>
                        <div className={`data-box ${Array.isArray(log.meta) ? ' gap-btw-array' : 'gap-btw'}`}>
                          {Array.isArray(log.meta) ? (
                            log.optimizationId ? (
                              <>
                                {/* Calculate totals for all meta items */}
                                <ShowCollectionDetails log={log}/>
                                {/* Display total values */}
                                
                              </>
                            ) : (
                              log.meta.map((metaItem, metaIndex) => (
                                <>
                                  <div className='meta-item' style={{gap: '9.11vw'}}>
                                    <p className='data-text' style={{width:'1.17vw'}}>{metaItem.slugName}</p>
                                    <p className='data-text' style={{width:'1.17vw'}}>{covertToKB(metaItem.originalImagesSize)}</p>
                                    <p className='data-text add-padding-left' style={{width:'1.17vw'}}>{covertToKB(metaItem.optimizedImagesSize)}</p>
                                    <p className='data-text add-padding-left' style={{width: '1.17vw'}}>{covertToKB(metaItem.totalSavedBytes)}</p>
                                    <p className='add-margin-right detail-class' style={{width:'1.17vw'}}>
                                      <Link to={`/logs/${metaIndex}/${log.jobId}`} className="details-link">
                                        Details
                                      </Link>
                                    </p>
                                  </div>
                                  {metaIndex !== log.meta.length - 1 && (<div className="divider-line"></div>)}
                                </>
                              ))
                            )
                          ) : (
                            <>
                              {/* Display individual meta items */}
                              <div className='meta-item'>
                                <p className='data-text'>{log.meta.slugName}</p>
                                <p className='data-text'>{covertToKB(log.meta.originalImagesSize)}</p>
                                <p className='data-text add-padding-left'>{covertToKB(log.meta.optimizedImagesSize)}</p>
                                <p className='data-text add-padding-left'>{covertToKB(log.meta.totalSavedBytes)}</p>
                                <p className='add-margin-right detail-class'>
                                  <Link to={`/logs/00/${log.jobId}`} className="details-link">
                                    Details
                                  </Link>
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                        {logIndex !== groupedLogs[dateTimeKey][collectionName].length - 1 && (<div className="divider-line"></div>)}
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserLogsPage;
