import React from "react";
import CustomButton from "../CustomButton/CustomButton.component";
import "./PricingPlan.styles.scss";

const PricingPlan = ({ planDetails, selectPrice }) => {
  const {
    name,
    smallDes,
    price,
    savePrice,
    btnText,
    ctaUrl,
  } = planDetails;

  return (
    <div className="plan">
      <h2 className="plan-title">{name}</h2>
      <p className="desc">{smallDes}</p>
      <p className="plan-price">
        ${price[selectPrice]}
        <span className="price">/month</span>
      </p>
      <p className="save-price" dangerouslySetInnerHTML={{ __html: savePrice[selectPrice]}} />
      <a href={ctaUrl[selectPrice]} target="blank" className="custom-link">
        <CustomButton
          style={{
            fontSize: "14px",
            background: "#286ef1",
            color: "white",
          }}
        >
          {btnText}
        </CustomButton>
      </a>
    </div>
  );
};


const FeatureList = ({planDetails}) => {
  const { features } = planDetails;
  return (
    <ul className="feature-list">
        {
          features.map((feature, idx) => <li key={idx} className="feature">{feature}</li>)
        }
    </ul>
  )
}

export default PricingPlan;
export {FeatureList};
