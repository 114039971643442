import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  circularProgress: {
    position: 'relative',
    animation: 'rotate 2s linear infinite',
  },
  progressText: {
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    fontSize: '0.65rem',
  },
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
}));

export default function CircularProgressIndicator(props) {
  const classes = useStyles();
  const { value, ...otherProps } = props;
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.circularProgress} {...props}/>
      {value == null ? '' : (
      <div className={classes.progressText}>{`${(value)}%`}</div>)}
    </div>
  );
}
