import React, { useEffect, useState } from 'react';
import { getTokenFromLS, parseJSON } from "../../lib/helpers";
import { useParams,useHistory } from 'react-router-dom';
import './LogsDetails.styles.scss';
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs.component";
import { Breadcrumb } from '@bugsnag/js';
import Back from "../../images/Arrow back.svg"
import { constSelector } from 'recoil';

const UserLogsDetailsPage = () => {
  const [userLogsDetails, setUserLogsDetails] = useState([]);
  let { slugId, index } = useParams();
  const history = useHistory();
  const goBackBtn = () => {
    history.goBack();
  };
  useEffect(() => {
    fetchLogs();
  }, []);
  function handleImageError(imgElement,skip) {
    var textElement = document.createElement('p');
    textElement.textContent = "N/A";
    textElement.classList.add('data-text-details');
    if(skip){
      textElement.classList.add('adding-margin-skip');
    }else{
      textElement.classList.add('adding-margin');
    }
    imgElement.replaceWith(textElement);
  }
  const fetchLogs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-user-logs`, {
        headers: {
          'Content-Type': 'application/json',
          token: getTokenFromLS(),
        },
      });
      const data = await response.json();
      setUserLogsDetails(data);
    } catch (error) {
      console.error('Error fetching user logs:', error);
    }
  };

  const log = userLogsDetails && userLogsDetails.find((log) => log.jobId === slugId);
  let imagesData
  let skippedData
  if(index === '00'){
    imagesData = log && log.meta.compressedImageURL;
    skippedData = log && log.meta.skippedImages;
  }else{
    imagesData = log && log.meta[index].compressedImageURL;
    skippedData = log && log.meta[index].skippedImages;
  }
  console.log(imagesData)
  const checkSkippedItem = skippedData && skippedData.length !== 0 && skippedData[0].itemName;
  const logMeta = log && log.meta;
  const slugName = logMeta && (index === '00' ? logMeta.slugName : logMeta[index].slugName);


  const covertToKB = (size) =>{
    return (size/1024).toFixed(2)+'KB'
  }
  const covertToMB = (size) =>{
    return (size / 1024).toFixed(2) + 'MB';
  }
  const extractImageUrl = (imageUrl) => {
    const lastSlashscoreIndex = imageUrl.lastIndexOf('/');
    return imageUrl.substring(lastSlashscoreIndex + 1);
  };
  return (
    <div className='main-box'>
      {/* <div className='breadcrumbs'>
      <Breadcrumbs
        customNames={{
          [slugId]: "details"
        }}
      />
      </div> */}
      <div className='heading-field'>
        <button className="back-button" onClick={goBackBtn}><img src={Back}></img></button>
        {slugName || 'N/A'}
      </div>
    <div className="white-box">
      <div className='collection-box'>
        <h2 className="collection-name">{imagesData && imagesData.length} Optimized Images</h2>
        <div className={`light-grey header-box ${imagesData && imagesData.length !== 0 && imagesData[0].itemName ? 'gap-3vw' : 'gap-5vw'}`}>
          <p>Image Name</p>
          <p>Original Size</p>
          <p>Optimized Size</p>
          <p>Saved Size</p>
          {imagesData &&  imagesData.length != 0 && imagesData[0].itemName && (
            <p>Item Name</p>
          )}
          <p>Images</p>
        </div>
        {imagesData && imagesData.map((log, logIndex) => (
        <div className="grp-data">

            <div className={`data-box-details ${imagesData && imagesData.length !== 0 && imagesData[0].itemName ? 'gap-6vw' : 'gap-7vw'}`}>
              <div className='text-box data-text-details image-name'>
              {extractImageUrl(log.imageUrl)}
              </div>
              <div className='text-box'>
              <p className='data-text-details'>{covertToKB(log.originalSize)>10000?covertToMB((log.originalSize/1024)):covertToKB(log.originalSize)}</p>
              </div>
              <div className='text-box'>
              <p className='data-text-details add-left-padding'>{covertToKB(log.optimizedSize)>10000?covertToMB((log.optimizedSize/1024)):covertToKB(log.optimizedSize)}</p>
              </div>
              <div className='text-box'>
              <p className='data-text-details add-left-padding' style={{paddingLeft: imagesData && imagesData.length !== 0 && imagesData[0].itemName? '5.8vw': '7.3vw'}}> {covertToKB(log.savedBytes)>10000?covertToMB((log.savedBytes/1024)):covertToKB(log.savedBytes)}</p>
              </div>
              {imagesData && imagesData.length != 0 && imagesData[0].itemName && log.itemName && log.itemName.trim() !== '' && (
                <div className='text-box '>
                  <p className='data-text-details item-name' style={{paddingLeft: '7.6vw'}}>{log.itemName}</p>
                </div>
              )}
              <div className="data-group" style={{marginLeft:imagesData && imagesData.length !== 0 && imagesData[0].itemName ? '8.8vw':'6.8vw',width:'6.51vw',height:'13.38vh'}}>
              <img  src={log.imageUrl} style={{height:'auto',width:'100%'}} alt="Image" className="small-image" onError={(e) => handleImageError(e.target,false)}/>
              </div>
            </div>
            {logIndex !== imagesData.length - 1 && <div className="divider-line"></div>}
        </div>
      ))}

      </div>
      <div className='collection-box'>
        <h2 className="collection-name">{skippedData && skippedData.length} Skipped Images</h2>
        <div className="light-grey-skip header-box" style={{gap:skippedData && skippedData.length !== 0 && skippedData[0].itemName ? '2vw' : '2.929vw'}}>
          <p>Image Name</p>
          <p>Original Size</p>
          <p>Optimized Size</p>
          <p>Saved Size</p>
          <p>Skip Reason</p>
          {checkSkippedItem && (
            <p>Item Name</p>
          )}
          <p>Images</p>
        </div>
        {skippedData && skippedData.map((log, logIndex) => (
        <div className="grp-data">

            <div className="data-box-skip" style={{gap:checkSkippedItem ? '3.03vw' : '3.645vw'}}>
            <div className='text-box data-text-details image-name'>
              {extractImageUrl(log.imageUrl)}
              </div>
              <div className='text-box'>
              <p className='data-text-details add-left-padding-main'>{(log.originalSize/1024).toFixed(2)}KB</p>
              </div>
              <div className='text-box'>
              <p className='data-text-details add-left-padding' style={{paddingLeft:'6.5vw'}}>{(log.optimizedSize/1024).toFixed(2)}KB</p>
              </div>
              <div className='text-box'>
              <p className='data-text-details add-left-padding add-left-padding-saved'style={{paddingLeft:checkSkippedItem?'9.5vw':'11vw'}}>{(log.savedBytes/1024).toFixed(2)}KB</p>
              </div>

              <div className='text-box'>
                  <p className='data-text-details add-left-padding-skip' style={{paddingLeft:checkSkippedItem?'13vw':'14.8vw'}}>
                  {log.skipReason.match(/^file type (.+) is not supported.$/)
                      ? `Image type not supported.`
                      : log.skipReason === 'Size increased after compression'
                      ? 'Already compressed'
                      : log.skipReason} 
                  </p>              
              </div>
              {skippedData && skippedData.length != 0 && skippedData[0].itemName && log.itemName && log.itemName.trim() !== '' && (
                <div className='text-box '>
                  <p className='data-text-details item-name' style={{marginLeft:"17.5vw"}}>{log.itemName}</p>
                </div>
              )}
              <div className="data-group" style={{marginLeft:checkSkippedItem?'20.6vw':'18.6vw' ,width:checkSkippedItem?'4.51vw':'6.51vw',height:checkSkippedItem?'9.38vh':'13.38vh'}}>
              <img src={log.imageUrl} style={{height:'auto',width:'100%'}} alt="Image" className="small-image-skip" onError={(e) => handleImageError(e.target,true)}/>
              </div>

            </div>
            {logIndex !== imagesData.length - 1 && <div className="divider-line"></div>}
        </div>
      ))}

      </div>
  </div>
  </div>
  );
};

export default UserLogsDetailsPage;

