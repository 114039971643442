import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';
import IOI from '../../images/image-optimizer-image.svg'
import './Product.styles.scss';

const useStyles = makeStyles({
    root: {
        maxWidth: 220,
        margin: '20px 15px',
        borderRadius: 20
    },
    media: {
        height: 180,
        backgroundPosition: 'top'
    },
});

export default function Product() {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <Link to="/pixie" className="link-block">
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={IOI}
                        title="One Click Image Optimizer"
                    />
                    <CardContent align="center">
                        <h2 className="product-name">Pixie</h2>
                        <p className="desc">
                            Optimize your Webflow images including CMS items in a single click.
                        </p>
                    </CardContent>
                </CardActionArea>
            </Link>
        </Card>
    );
}
