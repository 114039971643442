import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function SkeletonUI({width, height}) {
  return (
    <div className="mr-lr">
      <Skeleton variant="rect" width={ width || 210 } height={height || 118} />
      <Skeleton variant="text" />
    </div>
  );
}
