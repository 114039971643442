import React,{useRef,useEffect} from "react";
import { Link } from 'react-router-dom';
import LinearProgress from '../../components/LinearProgress/LinearProgress.components';
import { makeStyles } from "@material-ui/core/styles";
import "./BottomPopup.styles.scss";
import CustomButton from "../CustomButton/CustomButton.component";
import {removeFromIndexedDB } from '../../dataFiles/indexedDB';

const BottomPopup = ({ text,subtext, buttonLabel, onButtonClick, showProgress, nums,link,type }) => {
  const handleBackButtonClick = async () => {
    localStorage.removeItem('optimizationStatus')
    localStorage.removeItem("jobId");
    localStorage.removeItem('totalFields');
    // localStorage.removeItem("collectionDataState")
    await removeFromIndexedDB("collectionDataState")
  };
  return (
    <div className="bottom-popup">
      <div className="popup-content">
        {showProgress ? (
            <LinearProgress num={nums} type={type} />
        ) : (
          <><div className="whole-text">
            <p className="popup-text">{text}</p>
            {subtext && <p className="popup-subtext">{subtext}</p>}
            </div>
            {link ?( 
            <Link to={type === 'convert' ? '/convert-to-webp' : '/pixie'}>
            <CustomButton
              style={{ width: "13.02vw", marginRight: "12.89vw" }}
              black={true}
              onClick={handleBackButtonClick}
            >
              {buttonLabel}
            </CustomButton>
            </Link>) : (   
            <CustomButton
              style={{ width: "13.02vw", marginRight: "12.89vw" }}
              black={true}
              onClick={onButtonClick}
            >
              {buttonLabel}
            </CustomButton>
            )}

          </>
        )}
      </div>
    </div>
  );
};

export default BottomPopup;