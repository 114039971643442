import React from 'react'
import { Redirect, Route } from 'react-router-dom'


const ProtectedRoute = ({ children: Component, isSignedIn,dataLoaded, ...rest }) => (
    console.log(dataLoaded),
    <Route {...rest} render={(props) => (
        (dataLoaded) &&(
        (isSignedIn) ?
            (Component) : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
    )} />
);

export default ProtectedRoute;