import React from 'react';
import Product from '../../components/Product/Product.component';
import './Products.styles.scss';

const Products = () => {
  return (
    <div>
      <div className="hero-block">
        <h2 className="title">
          Welcome to Little Big Things!
        </h2>
        <p className="desc">
          Let's make your Webflow site load faster, every-time.
        </p>
      </div>

      <div className="app-list-block">
        <Product />
      </div>
    </div>
  )
}

export default Products;