import React from 'react';
import './ErrorFallBack.styles.scss';


const ErrorFallBack = () => {
    return (
        <div className="error-comp">
            <h3 className="err-msg">
                Oops, We are sorry for this. Help us making our App better by giving your feedback on <a href={"mailto:veer@littlebigthings.dev"}>veer@littlebigthings.dev</a> 
            </h3>
        </div>
    )
}

export default ErrorFallBack;