import React from "react";

import CustomButton from "../CustomButton/CustomButton.component";

const OauthBtn = () => {
  const clientId = process.env.REACT_APP_WEBFLOW_CLIENT_ID || "";
  const url = `https://webflow.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20sites%3Aread`;
  return (
    <a href={url} className="custom-link">
      <CustomButton black={true}>Add Sites</CustomButton>
    </a>
  );
};

export default OauthBtn;
