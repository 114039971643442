import React, { useState,useEffect, useContext, useRef } from "react";
import { parseJSON } from "../../lib/helpers";
import { Link, useParams, useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import { saveData,getData,removeFromIndexedDB } from '../../dataFiles/indexedDB';
import ActionAlerts from "../../components/Alert/Alert.component";
import "./Collections.styles.scss";
import { CollectionContext } from "../../contexts/CollectionContext";
import Back from "../../images/Arrow back.svg"
import CollectionFieldsWithOptions from "../../components/CollectionFieldsWithOptions/CollectionFieldsWithOptions.component";
import { Tune } from "@material-ui/icons";
// import OptimizationTypeBox from "../../components/OptimizationTypeBox/OptimizationTypeBox.component";

function Collections() {
  const {
    fetchCollections,
    setAppApiKey,
    setAppSiteId,
    submitChange,
    isBtnDisabled,
    message,
    btnText,
    showOptimizeBtn,
    showPricingBtn,
  } = useContext(CollectionContext);

  let { api, siteId } = useParams();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const [sitesData, setSitesData] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [disable,setDisable] = useState(true);
  const [buttonClick,setButtonClick] = useState(false);
  const [checkOptimization,setCheckOptimization] = useState(false);

  const handleSelectAllClick = () => {
    if(!disable){
      console.log("selectALL: ",selectAllChecked)
      if(selectAllChecked === true){
        setButtonClick(true)
      }else{
        setButtonClick(false)
      }
      setSelectAllChecked(!selectAllChecked);
    }
  };

  const backButtonRef = useRef(null);

  const handleBackButtonClick = async () => {
    if (!checkOptimization) {
      // localStorage.removeItem("collectionDataState")
      localStorage.removeItem('optimizationStatus')
      localStorage.removeItem("jobId");
      localStorage.removeItem('totalFields');
      await removeFromIndexedDB("collectionDataState");
    }
  };

  useEffect(() => {
    if (backButtonRef.current) {
      backButtonRef.current.addEventListener("click", handleBackButtonClick);
    }

    return () => {
      if (backButtonRef.current) {
        backButtonRef.current.removeEventListener("click", handleBackButtonClick);
      }
    };
  }, [checkOptimization]);

  useEffect(() => {
    const handleCustomCheckboxChange = (event) => {
      const isChecked = event.detail;
        setSelectAllChecked(isChecked)
        setButtonClick(false)
    };
  
    document.addEventListener('checkboxChange', handleCustomCheckboxChange);
  
    return () => {
      document.removeEventListener('checkboxChange', handleCustomCheckboxChange);
    };
  }, []);

  useEffect(() => {

    const handleDisable = () => {
      setDisable(false)
      setCheckOptimization(false)
    };

    document.addEventListener('collectionData', handleDisable);

    return () => {
      document.removeEventListener('collectionData', handleDisable);
    };
  }, []);


  useEffect(() => {

    const handleOptimizationInProgress = () => {
      setDisable(true)
      setCheckOptimization(true)
    };

    document.addEventListener('optimizationInProgress', handleOptimizationInProgress);

    return () => {
      document.removeEventListener('optimizationInProgress', handleOptimizationInProgress);
    };
  }, []);

  useEffect( () => {
    setAppApiKey(api); 
    setAppSiteId(siteId);
    let collections;
    (async () => {
      collections = await getData("collectionDataState");
      console.log((collections));
      if(collections === null || collections === undefined){
        fetchCollections({ api, siteId });
      }else{
        const parsedCollection = JSON.parse(collections);
        if(parsedCollection.collectionData.length === 0){
          fetchCollections({ api, siteId });
        }
      }
    })();

    (async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}api/user-sites/${api}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: window.localStorage.getItem("token"),
        },
      }
    );
    const data =  await response.json();
    setSitesData(data);
    })();
  }, []);
  let SiteName = "";
  if (sitesData.mappedSitesData) {
    const matchedSite = sitesData.mappedSitesData.find(data => data.siteUniqueId === siteId);
    SiteName = matchedSite ? matchedSite.name : "";
  }
  // const toggleSelectAll = () => {
  //   const updatedCollectionData = collectionData.map(collection => ({
  //     ...collection,
  //     collectionFields: collection.collectionFields.map(field => ({
  //       ...field,
  //       isChecked: !selectAllChecked,
  //     })),
  //   }));
  //   updateCollectionData(updatedCollectionData);
  //   setSelectAllChecked(!selectAllChecked);
  // };
    return (
    <div className="main-block">
      <Container style={{ margin:'0',padding:'0'}} maxWidth="xl">
        <div className="top-bottom-block">
          <div className="top-sec">
            <div className="head-sec">
              <div className="top-head">
              <Link to={type === 'convert'? '/convert-to-webp' : '/pixie'}>
              <button className="back-button" ref={backButtonRef}><img src={Back}></img></button>
              </Link>
              <div className="site-nam">
                {SiteName}
              </div>
              </div>
              <div className="lower-sec">
                <div>
              <p className="head-lite">
                Select the items you would like to {type === 'convert' ? 'convert' : 'optimize'}
              </p>
              <p className="info-text">
                You will not be charged for the images that are already {type === 'convert' ? 'converted' : 'optimized'}.
              </p>
              </div>
              <CustomButton style={{ width: '11.97vw' ,marginBottom:'5.35vh',marginLeft:'0.84vw'}} disable={disable} onClick={handleSelectAllClick} black={true}> {(selectAllChecked) ? "Deselect All" : "Select All"}</CustomButton>
              </div>
            </div>

            {/* <OptimizationTypeBox /> */}
            <div className="collections-scroll-container">
            <div className="collection-field">
            <CollectionFieldsWithOptions selectAllChecked={selectAllChecked} byButtonClick={buttonClick} type={type}/>
            </div>
            </div>
            {/* <div className="alerts">
              {message.msg && (
                <ActionAlerts text={message.msg} type={message.type} />
              )}
            </div> */}
          </div>
        
          {/* <div className="nxt-prv">
          <div className="button-container">

            {showOptimizeBtn && (
              <CustomButton
                onClick={submitChange}
                mr={true}
                disabled={isBtnDisabled}
                black={!isBtnDisabled}
              >
                {btnText}
              </CustomButton>
            )}
            {showPricingBtn && (
              <Link to="/pixie/pricing">
                <CustomButton black={true}>Upgrade Plan</CustomButton>
              </Link>
            )}
            <CustomButton onClick={goBackBtn} black={true} mr={true}>
                Back
              </CustomButton>
            </div>

          </div> */}
        </div>
      </Container>
    </div>
  );
}

export default Collections;
