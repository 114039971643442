import React, { useState } from 'react';
import './ProfilePage.styles.scss';
import { Button } from '@material-ui/core';
import EditImg from '../../images/edit.svg';
import FormInput from '../../components/FormInput/FormInput.component';
import Bugsnag from '@bugsnag/js';
import { useFormValidation } from '../../customHooks/formValidationHook';
import Swal from 'sweetalert2';
import CustomButton from '../../components/CustomButton/CustomButton.component';

function ProfilePage({ user, subscription }) {
    const { formErrors,validateForm,checkForFormErrors,btnDisabled} = useFormValidation();

    const [username, setUsername] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [editMode, setEditMode] = useState(false);

    const handleChange = event => {
        const { value, name } = event.target;
        checkForFormErrors(name, value);
        if (name === 'userName') {
            setUsername(value);
        } else if (name === 'email') {
            setEmail(value);
        }
    };
    const onClickEdit = () => {
        checkForFormErrors("userName",username)
        checkForFormErrors("email", email);
        setEditMode(true);
    };

    const onClickCancel = () => {
        setUsername(user.name);
        setEmail(user.email);
        setEditMode(false);

    };
    const onClickLoadSetting = async () => {
        try {

                const token = window.localStorage.getItem("token");
                if (token) {
                    await fetch(`${process.env.REACT_APP_API_ENDPOINT}manage-payment-portal`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            token: token,
                        },
                    }).then(async (result) => {
                        const res = await result.json()
                        if (res.url) {
                            window.location.href = res.url;
                        }
                    }).catch(err => console.log("error: fetch API on selected plan =>", err))
                }
        } catch (error) {
            console.log("error on click selected plan =>", error)
        }
    }
    const onProfileUpdate = () => {
        const token = window.localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_ENDPOINT}user/update-user-profile`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify({ 
                formInput: { email, name: username }
            })
        }) 
        .then(response => response.json())
        .then(data => {
            setEditMode(false)
            this.props.toggleModal();
            this.props.loadUser({ ...this.props.user, ...this.state })
        })
        .catch(err => {
            console.log({err});
            Bugsnag.notify(err);
        });
    };
    const onAccountDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: (login) => {
                const token = window.localStorage.getItem('token');
                return fetch(`${process.env.REACT_APP_API_ENDPOINT}user/`, {
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
                }).then(response => {
                    if (!response.ok && response.status !== 400) {
                        throw new Error(response.statusText)
                    }
                    return response.json()
                })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    if (result.value.success) {
                        localStorage.clear();
                        let timerInterval;
                        Swal.fire({
                            title: 'Deleted!',
                            html: '<span style="color:orange;">We are looking forward to see you again.</span><br/><br/><sup>Redirecting to Home in <b></b> seconds.</sup>',
                            timer: 10000,
                            icon: 'success',
                            confirmButtonText: 'Redirect now',
                            timerProgressBar: true,
                            allowOutsideClick: false,
                            didOpen: () => {
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = Math.ceil(Swal.getTimerLeft() / 1000)
                                }, 600)
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                                window.location = '/login';
                            }
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'You have 1 active subscription.',
                            text: `You can only delete your account once your subscription is over.`,
                            footer: '<a href="/pixie/pricing">Check your Subscriptions here</a>'
                        })
                    }
                }
            })
    }

    const onPlans = () =>{
        window.location.href = "/pixie/pricing"
    }

    return (
        <div className="profile-main">
            <div className="white-box-profile">
                <div className='profile-container'>
                    <div className="profile-heading">
                        Profile Details
                    </div>
                    {user.type === "BASIC" && (
                        <div className='edit-button' onClick={onClickEdit}>Edit  <img src={EditImg} style={{ marginLeft: '5px' }} alt="Edit" /></div>
                    )}
                </div>
                <div className='form-profile'>
                <div className='details-container-name'>
                    <div className='name-container'>Your Name</div>
                    <div>
                        <FormInput  name="userName" className={`box-input ${formErrors.userName && editMode? 'error-box' : ''}`} disable={!editMode} value={username} handleChange={handleChange}/>
                        {editMode &&(formErrors.userName && <span className='error'>{formErrors.userName}</span>)}
                    </div>
                </div>
                <div className='details-container-email'>
                    <div className='email-container'>Email</div>
                    <div>
                        <FormInput type="email" name="email" disable={!editMode} className={`box-input ${formErrors.email && editMode? 'error-box' : ''}`} value={email} handleChange={handleChange}/>
                        {editMode &&(formErrors.email && <span className='error'>{formErrors.email}</span>)}
                    </div>
                </div>
                {/* <div className='payment-details'>
                    <div className='payment-container'>Credit/Debit Card</div>
                    <div className='billing' onClick={subscription.isSubscribed?onClickLoadSetting:onPlans}>Billing Portal</div>
                </div> */}
                </div>
                {editMode && (                
                <div className="actions-btn">
                    <div className='save-cancel'>
                        <CustomButton style={{ width: '20%' ,marginRight:'20px'}} disabled={btnDisabled} type='submit' black={true} onClick={onProfileUpdate}>Save</CustomButton>
                        <CustomButton style={{width: '20%',marginLeft:'20px' }} black={true} onClick={onClickCancel}>Cancel</CustomButton>
                    </div>
                </div>)}
                {user.type === "BASIC" &&(
                    <div className={`delete-btn ${editMode ? 'edit-mode-on' : 'edit-mode-off'}`}>
                        <CustomButton style={{backgroundColor: 'red', color: 'black', fontWeight: 'bold',width:'40%'}} onClick={onAccountDelete}>Delete Account</CustomButton> 
                
                    </div>
                )}

            </div>
                
        </div>
    );
}

export default ProfilePage;
