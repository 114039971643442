import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { Link, useParams } from "react-router-dom";
import ActionAlerts from "../../components/Alert/Alert.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import FormInput from "../../components/FormInput/FormInput.component";
import { useFormValidation } from "../../customHooks/formValidationHook";
import CircularProgressIndicator from "../../components/CircularProgress/CircularProgress.component";
import "./VerifyResetPassword.styles.scss";

const VerifyResetPassword = ({ isSignedIn }) => {
  const [userDetails, setUserDetails] = useState({
    password: '',
    confirmPassword: '',
});
  const [titleText, setTitleText] = useState(
    "Please Wait while we verify your token.."
  );
  const [btnText, setBtnText] = useState("Submit");
  const [message, setMessage] = useState({ msg: "", type: "" });
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  let { token } = useParams();

  const {
    formErrors,
    validateForm,
    checkForFormErrors,
    btnDisabled,
    setBtnDisabled,
  } = useFormValidation();

  const { password, confirmPassword } = userDetails;


  useEffect(() => {
    if (isSignedIn) {
      localStorage.clear();
    } else if (token) {
      verifyToken(token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  const verifyToken = (uniKey) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}user/reset/${uniKey}`)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setShowForm(true);
          setTitleText("Enter Your Details Below");
        } else {
          setMessage({ msg: data.message, type: "error" });
          setTitleText("Reset Token is Invalid, Please try to reset again");
        }
      })
      .catch((err) => {
        Bugsnag.notify(err);
      });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    checkForFormErrors(name, value);
    setUserDetails(prevDetails => {
      return {
          ...prevDetails,
          [name]: value,
      }
  });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (validateForm(formErrors)) {
      setBtnDisabled(true);
      setBtnText("Please Wait...");
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/reset/${token}`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserDetails({
            password: '',
            confirmPassword: ''
          })
          if (data.success) {
            setBtnText("Done");
            setShowForm(false);
            setMessage({ msg: 'Successfully Changed Password, Please Login', type: "success" })
        } else {
            setMessage({ msg: data.message, type: "error" })
            setBtnText("Submit")
          }
        })
        .catch((err) => {
          Bugsnag.notify(err);
        });
    }
  };


  return (
    <div className="flex-center">
      <div className="sign-in">
        <h2 className="title">{titleText}</h2>

        {message.msg ? (
          <ActionAlerts text={message.msg} type={message.type} />
        ) : null}

        {showForm ? (
          <form onSubmit={onSubmit} className="login-form">
            <FormInput
              type="password"
              name="password"
              value={password}
              onChange={handleChange}
              label="Password"
              required
            />
            {formErrors.password && (
              <span className="error">{formErrors.password}</span>
            )}
            <FormInput
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange}
              label="Confirm Password"
              required
            />
            {confirmPassword.length && password !== confirmPassword ? (
              <span className="error">Passwords didn't match</span>
            ) : null}
            <div className="buttons">
              <div className="center tb-margin-20">
                <CustomButton type="submit" disabled={btnDisabled}>
                  {btnText}
                </CustomButton>
              </div>
            </div>
          </form>
        ) : (
          loading ? 
            <CircularProgressIndicator /> :
            ( <p className="non-user">
              Click <Link className="link" to='/login'>Here</Link> to Login
              </p> )
        )}
      </div>
    </div>
  );
};

export default VerifyResetPassword;
