import React from 'react';

import './CustomButton.styles.scss';

const CustomButton = ({ children, black, mr, ml, ...otherProps }) => (
    <button className={`${black ? 'black-white' : ''} ${mr ? 'mr' : ''} ${ml ? 'ml' : ''} custom-button`} {...otherProps}>
        {children}
    </button>
);

export default CustomButton;