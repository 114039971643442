import React, { useState, createContext, useEffect } from "react";
import { getTokenFromLS, parseJSON } from "../lib/helpers";

export const UserQuotaContext = createContext();

const UserQuotaContextProvider = ({ children }) => {
    const [userQuota, setUserQuota] = useState(0);
    
    useEffect(() => {
      if (getTokenFromLS()) {
        getUserQuota();
      }
    }, []);
  
    const getUserQuota = () => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-user-quota`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: getTokenFromLS(),
        },
      })
        .then(parseJSON)
        .then((data) => {
          if (!data.success) throw new Error("Failed to fetch userQuota");
          setUserQuota(data.userQuotaLeft);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    return (
        <UserQuotaContext.Provider value={{userQuota}}>
        {children}
      </UserQuotaContext.Provider>
    )
}

export default UserQuotaContextProvider;