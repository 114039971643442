import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { useHistory, useLocation } from "react-router-dom";
import ActionAlerts from "../../components/Alert/Alert.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import FormInput from "../../components/FormInput/FormInput.component";
import { useFormValidation } from "../../customHooks/formValidationHook";
import "./ResetPassword.styles.scss";

const ResetPassword = ({ isSignedIn }) => {
  const [userEmail, setUserEmail] = useState("");
  const [btnText, setBtnText] = useState("Submit");
  const [message, setMessage] = useState({ msg: "", type: "" });
  const [showForm, setShowForm] = useState(true);
  const [isFilled, setIsFilled] = useState(false);
  const {
    formErrors,
    validateForm,
    checkForFormErrors,
    btnDisabled,
    setBtnDisabled,
  } = useFormValidation();

  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (isSignedIn) {
      history.replace(from);
    }
  }, [from, history, isSignedIn]);

  const handleChange = (event) => {
    const { value, name } = event.target;
    checkForFormErrors(name, value);
    setUserEmail(value);
    setIsFilled(value.trim() !== '');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (validateForm(formErrors)) {
      setBtnDisabled(true);
      setBtnText("Please Wait...");
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/recover`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userEmail,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserEmail("");
          if (data.success) {
            setBtnText("Sent");
            setMessage({
              msg: "Successfully sent, Please check your Email",
              type: "success",
            });
            setShowForm(false);
          } else {
            setMessage({ msg: data.message, type: "error" });
            setBtnText("Submit");
          }
        })
        .catch((err) => {
          Bugsnag.notify(err);
        });
    }
  };

  return (
    <div className="flex-center">
      <div className="sign-in">
        {message.msg ? (
          <ActionAlerts text={message.msg} type={message.type} />
        ) : null}
        {showForm ? (
          <>
            <h2 className="title">Enter your Email below to Reset</h2>
            <form onSubmit={onSubmit} className="login-form">
              <FormInput
                className = "box-input-rs"
                name="email"
                type="email"
                value={userEmail}
                handleChange={handleChange}
                label="Email"
                required
              />
              {formErrors.email && (
                <span className="error">{formErrors.email}</span>
              )}
              <div className="buttons">
                <div className="center tb-margin-20">
                  <CustomButton type="submit" disabled={btnDisabled} black={isFilled ? 'black' : ''}>
                    {btnText}
                  </CustomButton>
                </div>
              </div>
            </form>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ResetPassword;
