import React from 'react';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import FormInput from '../FormInput/FormInput.component';
import CustomButton from '../CustomButton/CustomButton.component';
import Swal from 'sweetalert2';

import './Profile.styles.scss';
import Bugsnag from '@bugsnag/js';

class Profile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: this.props.user.name,
            email: this.props.user.email,
            isSubscribed: this.props.sub.isSubscribed,
            planName: this.props.sub.planName,
            type: this.props.user.type,
        }
    }

    onFormChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value}); 
    }

    onProfileUpdate = () => {
        const token = window.localStorage.getItem('token');
        const { username, email } = this.state;
        fetch(`${process.env.REACT_APP_API_ENDPOINT}user/update-user-profile`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify({ 
                formInput: { email, name: username }
            })
        }) 
        .then(response => response.json())
        .then(data => {
            this.props.toggleModal();
            this.props.loadUser({ ...this.props.user, ...this.state })
        })
        .catch(err => {
            console.log({err});
            Bugsnag.notify(err);
        });
    } 
  
    onAccountDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: (login) => {
                const token = window.localStorage.getItem('token');
                const { username, email } = this.state;
                return fetch(`${process.env.REACT_APP_API_ENDPOINT}user/`, {
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                        'token': token
                    }
                }).then(response => {
                    if (!response.ok && response.status !== 400) {
                        throw new Error(response.statusText)
                    }
                    return response.json()
                })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    if (result.value.success) {
                        localStorage.clear();
                        let timerInterval;
                        Swal.fire({
                            title: 'Deleted!',
                            html: '<span style="color:orange;">We are looking forward to see you again.</span><br/><br/><sup>Redirecting to Home in <b></b> seconds.</sup>',
                            timer: 10000,
                            icon: 'success',
                            confirmButtonText: 'Redirect now',
                            timerProgressBar: true,
                            allowOutsideClick: false,
                            didOpen: () => {
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = Math.ceil(Swal.getTimerLeft() / 1000)
                                }, 600)
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                                window.location = '/login';
                            }
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'You have 1 active subscription.',
                            text: `You can only delete your account once your subscription is over.`,
                            footer: '<a href="/pixie/pricing">Check your Subscriptions here</a>'
                        })
                    }
                }
            })
    }


    render() {
        return (
        <div className="profile-modal">
             <div className='profile-block'>
                <h2 className="title">
                    Hi {this.state.username} ! Your Profile
                </h2>
                <FormInput type='text'  disable={this.state.type === "WEBFLOW" ? true : false} name='username' value={this.state.username} label='Name' onChange={this.onFormChange} required />
                <FormInput type='email' disable={this.state.type === "WEBFLOW" ? true : false} name='email' value={this.state.email} label='Email' onChange={this.onFormChange} required />  
                <FormInput type='email' disable={true} name='email' value={this.state.isSubscribed ? this.state.planName : 'NO CURRENT SUBSCRIPTION'} label='Current Subscription' onChange={this.onFormChange} required />  
              {
                this.state.type === "WEBFLOW" ? "" :
                (<div className="space-btw">
                  <CustomButton style={{ width: '48%' }} type='submit' black={true} onClick={this.onProfileUpdate}>Save</CustomButton>
                  <CustomButton style={{ float: 'right', width: '48%' }} black={true} onClick={this.props.toggleModal}>Cancel</CustomButton>
                </div>)
              }
              <br/>
                <CustomButton style={{backgroundColor: 'red', color: 'black', fontWeight: 'bold'}} onClick={this.onAccountDelete}>Delete Account</CustomButton> 
                <div onClick={this.props.toggleModal} className="modal-close">
                    <CloseSharpIcon fontSize="large" style={{color: '#FACE48'}} />
                </div>  
            </div>
        </div>
    ) 
    }
}


export default Profile;