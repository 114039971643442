import React, { useState } from 'react';
import Modal from 'react-modal';
import './StraightForwardPricing.modal.css';
import failed from '../../images/failed.png';
import StraightForwardPricing from './StraightForwardPricing.component';
import { useHistory } from 'react-router-dom';

function StraightForwardPricingModalFailed() {
  const [modalIsOpen, setIsOpen] = useState(true);

  const history = useHistory();
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(31, 31, 31, .9)',
    }
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    history.replace("/pixie/pricing")
  }

  return (
    <div>
      <div className=''><StraightForwardPricing /></div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal Content"
        className="Modal"
      >
        <div>
          <div className='main-modal'>
            <img className='img-modal' src={failed} alt="load" />
          </div>
          <div className='header-modal'>Payment Failed</div>
          <div className='content-modal-1'>Oops! Something went wrong.</div>
          <div className='content-modal-1'>Your payment wasn’t completed.</div>
          <div className='button-modal'><button onClick={() => history.replace("/pixie/pricing")}>RETRY PAYMENT</button></div>
        </div>
      </Modal >
    </div >
  );
}


export default StraightForwardPricingModalFailed;
