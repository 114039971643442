import React ,{ useEffect,useState }from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { parseJSON } from "../../lib/helpers";
import Bugsnag from "@bugsnag/js";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Modal from "../../components/Modal/Modal.component";
import OauthBtn from "../../components/OauthBtn/OauthBtn.component";
import { Link ,useLocation } from "react-router-dom";
import Image from '../../images/image.svg';
import Briefcase from '../../images/briefcase.svg';
import HelpCircle from '../../images/help-circle.svg';
import DollarSign from '../../images/dollar-sign.svg';
import LogOut from '../../images/log-out.svg';
import User from '../../images/user.svg';
import Webp from '../../images/webp.svg';
import CustomButton from '../CustomButton/CustomButton.component';
import './NavMenu.styles.scss';

const NavMenu = ({ onRouteChange, toggleModal }) => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [siteIds, setSiteIds] = useState([]);
  const [lastSubpage, setLastSubpage] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeOpenModal = () => {
    setIsModalOpen(prevState => !prevState);
}
  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log(lastSubpage)
  useEffect(() => {
    setSelectedOption(location.pathname);

    if (location.pathname.startsWith('/pixie/')) {
      setLastSubpage(location.pathname);
    }else if(location.pathname === '/pixie'){
      setLastSubpage(null)
    }
  }, [location]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-username-sites`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            token: window.localStorage.getItem("token"),
        },
    })
        .then(parseJSON) // parse it to Json
        .then((data) => {
            if (data.success) {
                const {
                    user: { sites, username },
                } = data;
                // if (sites instanceof Array && sites.length < 1) {
                    // window.location = url;
                // }
                setSiteIds(sites);
            }
        })
        .catch(err => {
            console.log({ err });
            Bugsnag.notify(err);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  const handleOptionClick = (path) => {
    if (path !== '/help') {
      setSelectedOption(path);
    }
    if (path === '/help') {
      window.open('https://www.trypixie.io/guide', '_blank');
    }
  };

  useEffect(() => {
    const nestedPath = location.pathname.split('/')[1];
    setSelectedOption(`/${nestedPath}`);
  }, [location]);

  // return (
  //   <div>
  //     <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
  //       <MenuRoundedIcon style={{ color: '#000000' }} fontSize="large" />
  //     </Button>
  //     <Menu
  //       id="simple-menu"
  //       anchorEl={anchorEl}
  //       keepMounted
  //       open={Boolean(anchorEl)}
  //       onClose={handleClose}
  //     >
  //       <MenuItem><Link to="/pixie/pricing" style={{
  //         textDecoration: "none",
  //         color: "rgb(33, 33, 33)"
  //       }}>View Pricing</Link></MenuItem>
  //       <MenuItem onClick={toggleModal}>View Profile</MenuItem>
  //       <MenuItem onClick={() => onRouteChange('signout')}>Log Out</MenuItem>
  //     </Menu>
  //   </div>
  // );
  
  return (
    <div className="navMenuContainer">
      
      <div className="menuBox">
        <div className={`menuItem ${selectedOption === '/pixie' && (type !== 'convert') ? 'selected' : ''}`}>
          <Link
          to={lastSubpage || '/pixie'} // Use lastSubpage if available, otherwise the default URL
          className="menuLink"
          onClick={() => handleOptionClick(lastSubpage || '/pixie')}
        >
          <img src={Image} alt="Icon 1" className="customIcon" />
          <span className='text-field'>Optimizer</span>
        </Link>
        </div>
        <div className={`menuItem ${selectedOption === '/convert-to-webp' || type === 'convert' ? 'selected' : ''}`}>
          <Link to="/convert-to-webp" className="menuLink" style={{marginLeft: '1.9vw'}} onClick={() => handleOptionClick('/convert-to-webp')}>
            <img src={Webp} alt="Icon 2" className="customIcon" style={{marginRight: '1.45vw', width: '30px', height: '30px' }} />
            <span className='text-field'>Convert To WebP</span>
          </Link>
        </div>
        <div className={`menuItem ${selectedOption === '/logs' ? 'selected' : ''}`}>
          <Link to="/logs" className="menuLink" onClick={() => handleOptionClick('/logs')}>
            <img src={Briefcase} alt="Icon 2" className="customIcon" />
            <span className='text-field'>Log History</span>
          </Link>
        </div>
        <div
          className={`menuItem ${selectedOption === '/help' ? 'selected' : ''}`}
          onClick={() => handleOptionClick('/help')}
        >
            <img src={HelpCircle} alt="Icon 3" className="customIcon" />
            <span className='text-field'>Help & FAQs</span>
        </div>
        <div className={`menuItem ${selectedOption === '/pixie/pricing' ? 'selected' : ''}`}>
          <Link to="/pixie/pricing" className="menuLink" onClick={() => handleOptionClick('/pixie/pricing')}>
            <img src={DollarSign} alt="Icon 4" className="customIcon" />
            <span className='text-field'>Subscription</span>
          </Link>
        </div>
        <div className={`menuItem ${selectedOption === '/profile' ? 'selected' : ''}`}>
          <Link to="/profile" className="menuLink" onClick={() => handleOptionClick('/profile')}>
            <img src={User} alt="Icon 5" className="customIcon" />
            <span className='text-field'>Profile</span>
          </Link>
        </div>       
        <div className="menuItem" onClick={() => onRouteChange('signout')}>
          <img src={LogOut} alt="Icon 6" className="customIcon" />
          <span className='text-field'>Log Out</span>
        </div>
      </div>

      {
                isModalOpen && (
                    <Modal>
                        <MultiOptionBox toggleModal={closeOpenModal} />
                    </Modal>
                )
            }
    </div>
  );
}
const MultiOptionBox = ({ toggleModal }) => {
  return (
      <div className="box-outline" id="modal-div">
          <div className="inner-box">
              <div onClick={toggleModal} className="modal-close">
                  <CloseSharpIcon fontSize="large" style={{ color: '#FACE48' }} />
              </div>

              <h3 className="title">
                  Add your Webflow site
              </h3>
              <p className="desc">
                  It is as simple as signing in your Webflow account.
              </p>
              <div className="btn-box">

                  <OauthBtn />
                  <span className="or">or</span>
                  <Link className="custom-links" to="/pixie/add-api-key">
                      <CustomButton black={true}>Add API Key</CustomButton>
                  </Link>
              </div>
          </div>
      </div>
  )
}
export default NavMenu;