export const JOB_IDS_LOCAL_STORAGE_KEY = "jobIds";

export const getJobIdsFromLS = () => {
    // Get JobsIds from LocalStorage
    const jobIdsArrFromLS = window.localStorage.getItem(
      JOB_IDS_LOCAL_STORAGE_KEY
    );
    const parsedJobIdsArrFromLS = JSON.parse(jobIdsArrFromLS);
    return parsedJobIdsArrFromLS;
  };

export const setJobIdsToLS = (arrOfIds) => {
    const jobIdsFromLS = getJobIdsFromLS();
    let dataToSave;
    if(jobIdsFromLS && jobIdsFromLS.length) {
       dataToSave = new Set([...jobIdsFromLS, ...arrOfIds])
    } else {
      dataToSave = [...arrOfIds];
    }
    const convertedArr = [...dataToSave]
    const jsonConvertedArr = JSON.stringify(convertedArr);
    localStorage.setItem('jobIds', jsonConvertedArr);
}

export const removeJobIdsFromLS = (arrOfIds = []) => {
  const jobIdsFromLS = getJobIdsFromLS();
    const filteredJobsIds = jobIdsFromLS.filter(id => !arrOfIds.includes(id))
    const jsonConvertedArr = JSON.stringify(filteredJobsIds);
    localStorage.setItem('jobIds', jsonConvertedArr);
}