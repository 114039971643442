import { useLocation } from 'react-router-dom';

const checkStatus = (response) => {
    if (response.status === 200) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error(response));
    }
}

const parseJSON = (response) => {
    return response.json();
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

// get job status data in object 
const jobStatusData = (arrData) => {
    return arrData.reduce((acc, cv) => {
        console.log("--cv", cv)
        console.log("--ac", acc)
        if (cv) {
            if (cv.state === "completed" || cv.state === "failed") {
                if (acc["completedJobs"]) {
                    acc["completedJobs"].push(cv.id);
                } else {
                    acc["completedJobs"] = [];
                    acc["completedJobs"].push(cv.id);
                }
            } else if (cv.state !== "completed") {
                if (acc["inCompleteJobs"]) {
                    acc["inCompleteJobs"].push(cv.id);
                } else {
                    acc["inCompleteJobs"] = [];
                    acc["inCompleteJobs"].push(cv.id);
                }
            }
        }
        return acc;
    }, {});
}

const getTokenFromLS = () => {
    const token = window.localStorage.getItem("token");
    if (!token) return null;
    return token;
}

export { checkStatus, parseJSON, useQuery, jobStatusData, getTokenFromLS };
