import React, { useState } from "react";
import { Link,useLocation } from "react-router-dom";
import "./SiteBox.styles.scss";
import "./OptimisationTypeModal.style.scss";
import Modal from "../../components/Modal/Modal.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import OauthBtn from "../../components/OauthBtn/OauthBtn.component";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import CMSIMAGE from "../../images/cms.svg";
import ITEMIMAGE from "../../images/items.svg";

const SiteBox = ({ siteInfo, reauth }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { previewUrl, name, siteId, siteUniqueId } = siteInfo;
  const [optimisationModal, setOptimisationModal] = useState(false);

  const closeOpenModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const closeOptimisationModal = () => {
    setOptimisationModal((prevState) => !prevState);
    console.log(optimisationModal);
  };

  console.log(reauth);
  if (reauth === true) {
    // Show the Modal with MultiOptionBox
    return (
      <>
        <div className="site-box" onClick={closeOpenModal}>
          <div
            className="site-img-block"
            style={{ backgroundImage: `url(${previewUrl})` }}
          ></div>
          <div className="site-info">
            <span className="site-name">{name}</span>
          </div>
        </div>

        {isModalOpen && (
          <Modal>
            <MultiOptionBox toggleModal={closeOpenModal} />
          </Modal>
        )}
      </>
    );
  }

  return (
    <>
      {optimisationModal && (
        <Modal>
          <SelectOptimisationType
            toggleModal={closeOptimisationModal}
            siteId={siteId}
            siteUniqueId={siteUniqueId}
          />
        </Modal>
      )}
      <div className="site-box" onClick={closeOptimisationModal}>
        {/* <Link to={`/pixie/${siteId}/collections/${siteUniqueId}`}> */}
        <div
          className="site-img-block"
          style={{ backgroundImage: `url(${previewUrl})` }}
        ></div>
        <div className="site-info">
          <span className="site-name">{name}</span>
        </div>
        {/* </Link> */}
      </div>
    </>
  );
};

const MultiOptionBox = ({ toggleModal }) => {
  return (
    <div className="box-outline" id="modal-div">
      <div className="inner-box">
        <div onClick={toggleModal} className="modal-close">
          <CloseSharpIcon fontSize="large" style={{ color: "#FACE48" }} />
        </div>

        <h3 className="title">Resync your Webflow site</h3>
        <p className="desc">
          We have shifted to Webflow’s v2 API for a better experience. Please re-sync your Webflow project to continue.
        </p>
        <div className="btn-box">
          <OauthBtn />
          <span className="or">or</span>
          <Link className="custom-links" to="/pixie/add-api-key">
            <CustomButton black={true}>Add API Key</CustomButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

// Modal component to select Optimisation type
const SelectOptimisationType = ({ toggleModal, siteId, siteUniqueId }) => {
  const location = useLocation();
  const path = location.pathname;
  console.log(path)
  // console.log(toggleModal)
  return (
    <div className="box-outline bg-grey" id="modal-div">
      <div className="modal-container">
        <div onClick={toggleModal} className="modal-close">
          <CloseSharpIcon fontSize="large" style={{ color: "#FACE48" }} />
        </div>
        <div className="modal-wrapper">
        <h3 className="modal-title">
          Select how you would like to {path === '/convert-to-webp' ? 'convert' : 'optimize'} the images
        </h3>
          <div className="modal-btn-wrapper">
            <Link
              className="modal-link-block"
              to={`/pixie/${siteId}/collections/${siteUniqueId}?type=${path === '/convert-to-webp' ? 'convert' : 'optimize'}`}
              >
              <img className="modal-btn-image" src={CMSIMAGE} alt="cms" />
              <p className="modal-btn-title">Whole Collections</p>
              <p className="modal-btn-sub-title">Collection Wise</p>
            </Link>
            <Link
              className="modal-link-block"
              to={`/pixie/${siteId}/itemoptimization/${siteUniqueId}?type=${path === '/convert-to-webp' ? 'convert' : 'optimize'}`}
            >
              <img className="modal-btn-image" src={ITEMIMAGE} alt="cms" />
              <p className="modal-btn-title">Specific Images</p>
              <p className="modal-btn-sub-title">
                Item Wise
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SiteBox;
