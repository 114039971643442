import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { getTokenFromLS } from "../../lib/helpers"; 
import Back from "../../images/Arrow back.svg"
import './CollectionLogs.styles.scss';

const CollectionLogs = () => {
  let { jobId } = useParams();
  const history = useHistory();
  const goBackBtn = () => {
    history.goBack();
  };
  const [logs, setLogs] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-optimized-jobs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: getTokenFromLS(),
        },
        body: JSON.stringify({
            jobId: jobId,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data)
      setLogs(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (jobId) {
      fetchData();
    }
  }, [jobId]);

  const covertToKB = (size) =>{
    return (size/1024).toFixed(2)+'KB'
  }

  return (
    <div className='main-box'>
    <div className='header-wrapper'>
    <div className='heading-field'>
        <button className="back-button" onClick={goBackBtn}><img src={Back}></img></button>
        Collection Details
      </div>  
    </div>
    <div className="white-box">
      {logs.map((log, index) => (
        <>
        <div key={index}>
          <div className="header-wrapper">
            <h3 className="collection-name">{log.meta[0].collectionName}</h3>
          </div>
          <div className='light-grey-box header-box'>
            <p>Field Name</p>
            <p>Original Size</p>
            <p>Optimized Size</p>
            <p>Saved Size</p>
            <p>Action</p>
          </div>
          {log.meta.map((metaItem, metaIndex) => (
            <>
            <div className='data-box gap-btw-array'>
            <div className='meta-item' key={metaIndex}>
              <p className='data-text'>{metaItem.slugName}</p>
              <p className='data-text'>{covertToKB(metaItem.originalImagesSize)}</p>
              <p className='data-text add-padding-left'>{covertToKB(metaItem.optimizedImagesSize)}</p>
              <p className='data-text add-padding-left'>{covertToKB(metaItem.totalSavedBytes)}</p>
              <p className='add-margin-right detail-class'>
                <Link to={`/logs/${metaIndex}/${log.jobId}`} className="details-link">
                  Details
                </Link>
              </p>
            </div>
            </div>
            {metaIndex !== log.meta.length - 1 && (<div className="divider-line"></div>)}
            </>
          ))}
        </div>
        </>
      ))}
    </div>
    </div>
  );
};

export default CollectionLogs;
