import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1.7361111111em",
    width:"100%",
  },
  processIndicator: {
    color: "#000",
    fontFamily: "Inter !important",
    fontSize: "1.6666666667em",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1,
  },
  linearProgress: {
    width:"100%",
    maxWidth: "33.6805555556em",
    height: "0.9027777778em",
    flexShrink: 0,
    borderRadius: "1em",
backgroundColor: "#D9D9D9",
    "& .MuiLinearProgress-bar": {
      borderRadius: "1em",
      backgroundColor: "#FFE46D",
    },
  },
});

export default function LinearDeterminate({ num,type }) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    setProgress((oldProgress) => {
      if (oldProgress === 100) {
        return 0;
      }
      return num;
    });
  }, [num]);

  return (
    <div className={classes.progressContainer}>
      {progress === 100?<div className={classes.processIndicator}>{type === 'convert' ?'Conversion' : 'Optimization'} Completed!</div>:<div className={classes.processIndicator}>{type === 'convert' ?'Converting...' : 'Optimizing...'}</div>}
      <LinearProgress
        variant="determinate"
        value={progress}
        className={classes.linearProgress}
      />
    </div>
  );
}
