import Bugsnag from "@bugsnag/js";
import { useState, useEffect, useRef } from "react";
import { parseJSON, jobStatusData, getTokenFromLS } from "../lib/helpers";

const JOB_IDS_LOCAL_STORAGE_KEY = "jobIds";

export function useJobHandler() {
  const [completedJobsArr, setCompletedJobsArr] = useState([]);
  const [failedJobsArr, setFailedJobsArr] = useState([]);
  const [jobIds, setJobIds] = useState([]); // []
  const [timerId, setTimerId] = useState(null);
  const [jobsInfo, setJobsInfo] = useState({});
  const [invalidIds, setInvalidIds] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  /**
   *  jobsInfo: {
   *  jobId: {
   *    status: error/success/processing,
   *    error: boolean
   *    success: boolean
   *    processing: boolean
   *    completedInfo/successData: {
   *    }
   * }
   * }
   */

  const jobIdsRef = useRef(jobIds);
  jobIdsRef.current = jobIds;

  const completedJobsArrRef = useRef(completedJobsArr);
  completedJobsArrRef.current = completedJobsArr;

  const failedJobsArrRef = useRef(failedJobsArr);
  failedJobsArrRef.current = failedJobsArr;

  const timerIdRef = useRef(timerId);
  timerIdRef.current = timerId;

  const jobsInfoRef = useRef(jobsInfo);
  jobsInfoRef.current = jobsInfo;

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // modify code to execute inner method only when jobs content has changed
  useEffect(() => {
    addJobsToLS();
    setTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobIdsRef.current]);

  const initialize = () => {
    const jobIdsFetchedFromLS = getJobIdssArrFromLS();
    if (jobIdsFetchedFromLS && jobIdsFetchedFromLS.length) {
      setJobIds(jobIdsFetchedFromLS);
      setTotalJobs(jobIdsFetchedFromLS.length);
    }
  };

  const getJobIdssArrFromLS = () => {
    // Get JobsIds from LocalStorage
    const jobIdsArrFromLS = window.localStorage.getItem(
      JOB_IDS_LOCAL_STORAGE_KEY
    );
    const parsedJobIdsArrFromLS = JSON.parse(jobIdsArrFromLS);
    return parsedJobIdsArrFromLS;
  };

  const addJobsToLS = () => {
    const jsonJobIds = JSON.stringify(jobIdsRef.current);
    localStorage.setItem(JOB_IDS_LOCAL_STORAGE_KEY, jsonJobIds);
  };


  const jobInfoSetter = (arrOfObj = []) => {
    // eslint-disable-next-line array-callback-return
    arrOfObj.forEach(obj => {
      if (obj !== undefined) {
        const jobInfoObj = {
          success: false,
          processing: true,
          collectionName: '',
          collectionFieldData: [],
          successData: null,
          id: '',
          state: '',
          progress: 0
        }
        const { success, id, collectionFieldData, collectionName, returnvalue, state, progress } = obj;


        jobInfoObj.id = id;
        jobInfoObj.state = state;
        jobInfoObj.progress = progress;
        jobInfoObj.collectionFieldData = collectionFieldData;
        jobInfoObj.collectionName = collectionName;

        if (success && state === "failed") {
          jobInfoObj.processing = false;
          jobInfoObj.success = false;
          setFailedJobsArr([...failedJobsArrRef.current, collectionName]);
        } else if (success && returnvalue) {
          jobInfoObj.processing = false;
          jobInfoObj.success = true;
          jobInfoObj.successData = returnvalue;
          setCompletedJobsArr([...completedJobsArrRef.current, collectionName]);
        }

        if (jobsInfoRef.current[collectionName]) {
          const oldData = jobsInfoRef.current[collectionName].filter(({ collectionFieldData }) => collectionFieldData.id !== jobInfoObj.collectionFieldData.id);
          setJobsInfo({ ...jobsInfoRef.current, [collectionName]: [...oldData, jobInfoObj] })
        } else {
          setJobsInfo({ ...jobsInfoRef.current, [collectionName]: [jobInfoObj] })
        }
        return jobInfoObj;
      }
    })
  }

  // If job id is Invalid
  const handleInvalidJobId = (jobId) => {
    setInvalidIds([...invalidIds, jobId]);
  }


  const jobsStatusChecker = (arr) => {
    console.log((arr))
    if (arr && arr.length) {
      // Map & fetch job status using Job ids
      Promise.all(
        arr.map((eachId) =>
          fetch(
            `${process.env.REACT_APP_API_ENDPOINT}api/optimize-collection/${eachId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                token: getTokenFromLS(),
              },
            }
          )
            .then(parseJSON) // parse it to Json
            .then(data => {
              if (data.success) {
                return data
              } else {
                const filteredArr = jobIdsRef.current.filter(id => id !== eachId);
                setJobIds(filteredArr);
                handleInvalidJobId(eachId);
              }
            })
            .catch(err => {
              Bugsnag.notify(err);
            })
        )
      )
        .then((data) => {
          console.log("data after hitting api", data);
          jobInfoSetter(data);
          console.log("failed jobs array", failedJobsArr)
          const { inCompleteJobs } = jobStatusData(data);
          console.log("incomplet jobs", inCompleteJobs)
          if (inCompleteJobs && inCompleteJobs.length) {
            setJobIds(inCompleteJobs);
          } else {
            setJobIds([]);
            clearTimer();
          }
          return data;
        })
        .catch(err => {
          Bugsnag.notify(err);
        })
    }
  };

  const setTimer = () => {
    if (!timerIdRef.current && jobIdsRef.current.length) {
      const interValId = setInterval(() => {
        jobsStatusChecker(jobIdsRef.current);
      }, 3000);
      setTimerId(interValId);
    }
  };

  const clearTimer = () => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }
  };

  return {
    completedJobsArr,
    failedJobsArr,
    jobIds,
    setJobIds,
    jobsInfo,
    invalidIds,
    totalJobs
  };
}
