import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { useHistory } from "react-router-dom";
import ActionAlerts from "../../components/Alert/Alert.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import FormInput from "../../components/FormInput/FormInput.component";
import "./VerifyLicenseKey.styles.scss";
import { getTokenFromLS, parseJSON } from "../../lib/helpers";

const VerifyLicenseKey = ({ isSignedIn }) => {
  const [licenseKey, setLicenseKey] = useState("");
  const [btnText, setBtnText] = useState("Verify");
  const [message, setMessage] = useState({ msg: "", type: "" });
  const [showForm, setShowForm] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [redirect, setRedirect] = useState(false);
  
  const history = useHistory();

  useEffect(() => {
    let interval = null;
    if (redirect) {  
       interval = setInterval(() => {
          // Redirect to another page
          history.push('/');
       }, 3000);
    }
    return () => interval ? clearInterval(interval) : null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect])

  useEffect(() => {
    if(licenseKey.length) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [licenseKey])

  const handleChange = (event) => {
    const { value } = event.target;
    setLicenseKey(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
      setBtnDisabled(true);
      setBtnText("Please Wait...");
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/verify-license`, {
        method: "post",
        headers: { 
          "Content-Type": "application/json",
          "token": getTokenFromLS()
        },
        body: JSON.stringify({ licenseKey }),
      })
        .then(parseJSON)
        .then((data) => {
          setLicenseKey("");
          if (data.success) {
            setBtnText("Verified");
            setMessage({
              msg: "Successfully Verified. Please Wait for 3 seconds while we redirect you.",
              type: "success",
            });
            setShowForm(false);
            setRedirect(true);
          } else {
            const errMessage = data.msg || 'Failed To Verify';
            setMessage({ msg: errMessage, type: "error" });
            setBtnText("Retry");
          }
        })
        .catch((err) => {
          Bugsnag.notify(err);
        });
  };

  return (
    <div className="flex-center">
      <div className="sign-in">
        {message.msg ? (
          <ActionAlerts text={message.msg} type={message.type} />
        ) : null}
        {showForm ? (
          <>
            <h2 className="title">Enter your License Key below</h2>
            <form onSubmit={onSubmit} className="login-form">
              <FormInput
                name="licenseKey"
                type="text"
                value={licenseKey}
                handleChange={handleChange}
                label="License Key"
                required
              />
             
              <div className="buttons">
                <div className="center tb-margin-20">
                  <CustomButton type="submit" disabled={btnDisabled}>
                    {btnText}
                  </CustomButton>
                </div>
              </div>
            </form>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default VerifyLicenseKey;
