import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import plansData from "../../dataFiles/pricingData";
import "./PixiePricing.styles.scss";
import PricingPlan, {
  FeatureList,
} from "../../components/PricingPlan/PricingPlan.component";

const PixiePricing = () => {
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [selectPrice, setSelectPrice] = useState("month");

  const handleChange = (event) => {
    const {
      dataset: { id },
    } = event.target;
    setSelectedPlan(id);
  };

  const handlePricing = (event) => {
    const {
      dataset: { type },
    } = event.target;
    setSelectPrice(type);
  };

  return (
    <div className="bg-color">
      <Box display="flex" justifyContent="center" flexDirection="column">
        <div className="head-sec-centre">
          <h1>Pixie - Pricing</h1>
          {/* <p>Available till the end of the beta.</p> */}

          <div className="year-month">
            <span className={selectPrice === 'month' ? 'active-plan' : ''} data-type="month" onClick={handlePricing}>
              Monthly
            </span>
            <span className={selectPrice === 'year' ? 'active-plan' : ''} data-type="year" onClick={handlePricing}>
              Yearly
            </span>
          </div>
        </div>
        <div className="plans">
          <PricingPlan
            planDetails={plansData[selectedPlan]}
            selectPrice={selectPrice}
          />
          <div className="sites">
            <h3 className="no-of-sites">Features</h3>
            <div className="three-col">
              {plansData.map((ele) => (
                <button
                  key={ele.id}
                  data-id={ele.id}
                  className={`styled-btn ${
                    Number(selectedPlan) === ele.id ? "selected-btn" : ""
                  }`}
                  onClick={handleChange}
                >
                  {ele.ctaName}
                </button>
              ))}
            </div>
          </div>
          <FeatureList planDetails={plansData[selectedPlan]} />
        </div>
      </Box>
    </div>
  );
};

export default PixiePricing;
