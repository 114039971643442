import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ActionAlerts from '../../components/Alert/Alert.component';
import FormInput from '../../components/FormInput/FormInput.component';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import {useFormValidation} from '../../customHooks/formValidationHook';
import SignupImage from '../../images/Group 80.png'
import posthog from 'posthog-js'

import './SignUp.styles.scss';
import Bugsnag from '@bugsnag/js';
const clientId = process.env.REACT_APP_WEBFLOW_CLIENT_ID || "";
const url = `https://webflow.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20sites%3Aread`;
function SignUp({ isSignedIn, setSignedIn }) {
    const [signUpDetails, setSignUpDetails] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [message, setMessage] = useState({ msg: '', type: "" });
    const [errorMessage, setErrorMessage] = useState('');
    const [userSignedIn, setUserSignedIn] = useState(false);
    const [btnText, setBtnText] = useState('SIGN UP');
    const { formErrors, validateForm, checkForFormErrors, btnDisabled, setBtnDisabled } = useFormValidation();
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/" } };

    useEffect(() => {
        if (isSignedIn) {
            setUserSignedIn(true)
            history.replace(from);
        }
    }, [from, history, isSignedIn, userSignedIn])
    const webflowLogin = async () => {
        window.location = url;
      };
    useEffect(() => {
        const { password, confirmPassword } = signUpDetails;
        if((!password.length || !confirmPassword.length) && password !== confirmPassword) {
            setBtnDisabled(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpDetails])

    const saveAuthTokenInSession = (token) => {
        window.localStorage.setItem('token', token);
    }


    const onSubmitSignUp = (event) => {
        event.preventDefault();
        const { name, email, password, confirmPassword } = signUpDetails;

        if(validateForm(formErrors) && password === confirmPassword) {
            setBtnDisabled(true);
            setBtnText('Please Wait...');
            fetch(`${process.env.REACT_APP_API_ENDPOINT}user/signup`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    username: name
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true && !data.errors) {
                        setMessage('Successfully Created Account')
                        window.fbq('track', 'StartTrial', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'});
                        posthog.capture('SignedUp',{
                            $set: {email: email},
                            email:email
                        });
                        saveAuthTokenInSession(data.token);
                        setSignedIn(true)
                        history.replace(from);
                        setSignUpDetails({
                            name: '',
                            email: '',
                            password: '',
                            confirmPassword: '',
                        })
                    } else {
                        errHandle(data?.msg || 'Error in Signing you in! Please try again');
                        setErrorMessage('Error in Signing you in! Please try again');

                    }
                }).catch(err => {
                    errHandle('Error in Signing you in! Please try again');
                    setErrorMessage('Error in Signing you in! Please try again');
                    Bugsnag.notify(err);
                })
        } else {
            setBtnDisabled(true);
        }
    }
    
  const errHandle = (errorMsg) => {
    setMessage({ msg: errorMsg, type: "error" })
    setBtnText('SIGN UP');
  }

    const handleChange = event => {
        const { name, value } = event.target;
        checkForFormErrors(name, value);
        setSignUpDetails(prevDetails => {
            return {
                ...prevDetails,
                [name]: value,
            }
        });
        const confirmEmpty = signUpDetails.confirmPassword.trim() === '';
        const filled = Object.values(signUpDetails).every(val => val.trim() !== '');
        const isValid = !confirmEmpty && filled;
        setAllFieldsFilled(isValid);
    }

    const { name, email, password, confirmPassword } = signUpDetails;
    return (
        <div className="flex-center">
            <div className="signup-container">
            <div className="signup-form">
            <h2 class="title">Sign up</h2>
            <p>Enter your credentials to create your account.</p>
                {
                    message.msg ?
                        <ActionAlerts text={message.msg} type={message.type} /> :
                        null
                }
                <div className="google-or-container">
              <div className="google-button-container">
              <button className="google-button" onClick={webflowLogin}>
                  Signup with Webflow
              </button>
              </div>
                </div>
                <div className="or-text tb-margin-24">or</div>
                <form  onSubmit={onSubmitSignUp}>
                <div className='tb-margin-24'>
                    <FormInput type='text' name='name' value={name} onChange={handleChange}
              className={`box-input-signup ${formErrors.userName || errorMessage ? 'error-box' : ''}`} label='Name' required />
                    {formErrors.userName && <span className='error'>{formErrors.userName}</span>}
                </div>
                <div className='tb-margin-24'>
                    <FormInput type='email' name='email' value={email} onChange={handleChange} className={`box-input-signup ${formErrors.email || errorMessage ? 'error-box' : ''}`}  label='Email' required />
                    {formErrors.email && <span className='error'>{formErrors.email}</span>}
                </div>
                <div className='tb-margin-24'>
                    <FormInput type='password' name='password' value={password} onChange={handleChange} className={`box-input-signup ${formErrors.password || errorMessage ? 'error-box' : ''}`} label='Password' required />
                    {formErrors.password && <span className='error'>{formErrors.password}</span>}
                </div>
                <div className='tb-margin-24'>
                    <FormInput type='password' name='confirmPassword' value={confirmPassword}  onChange={handleChange} className={`box-input-signup ${formErrors.confirmPassword || errorMessage ? 'error-box' : ''}`} label='Confirm Password' required />
                    {confirmPassword.length && password !== confirmPassword ? <span className='error'>Passwords didn't match</span> : null}
                    </div>
                    <div className="center">
            <CustomButton type='submit' disabled={btnDisabled} black={allFieldsFilled ? 'black' : ''}>{btnText}</CustomButton>
                    </div>

                </form>
                <div className="links-container">
                <div className="non-user">
                    Already have an account? <Link className="link" to='/login'>Login</Link>
                </div>
                </div>
            </div>
            <div className="signup-image">
          <img src={SignupImage} alt="Image 1" className="base-image" />

          </div>
        </div>
        </div>
    )
}

export default SignUp;