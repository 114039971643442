const pricing = [
    {
        id: 0,
        name: "Portfolio",
        smallDes: "For freelancers and single site owners",
        price: {
            month: 14,
            year: 11,
        },
        savePrice: {
            month: `Save <span class="save">$28</span> by switching to yearly pricing`,
            year: `Billed at <span class="center-dash">$168</span> $140/year | <span class="save">$28 savings </span>`,
        },
        btnText: "BUY",
        ctaName: "Portfolio",
        ctaUrl: {
            month: "https://gum.co/wclkp",
            year: "https://gum.co/wclkp",
        },
        learnMore: "",
        features: ['Unlimited Sites','1GB optimization','eCommerce Support','Image Resize'],
    },
    {
        id: 1,
        name: "Business",
        smallDes: "For businesses with more than 1 site",
        price: {
            month: 29,
            year: 24,
        },
        savePrice: {
            month: `Save <span class="save">$58</span> by switching to yearly pricing`,
            year: `Billed at <span class="center-dash">$348</span> $290/year | <span class="save">$58 savings </span>`,
        },
        btnText: "BUY",
        ctaName: "Business",
        ctaUrl: {
            month: "https://gum.co/wclkp",
            year: "https://gum.co/wclkp",
        },
        learnMore: "",
        features: ['Unlimited Sites','5GB optimization','eCommerce Support','Image Resize'],
    },
    {
        id: 2,
        name: "Agency",
        smallDes: "For agencies with more than 3 sites",
        price: {
            month: 39,
            year: 33,
        },
        savePrice: {
            month: `Save <span class="save">$78</span> by switching to yearly pricing`,
            year: `Billed at <span class="center-dash">$468</span> $390/year | <span class="save">$78 savings </span>`,
        },
        btnText: "BUY",
        ctaName: "Agency",
        ctaUrl: {
            month: "https://gum.co/wclkp",
            year: "https://gum.co/wclkp",
        },
        learnMore: "",
        features: ['Unlimited Sites','10GB optimization','eCommerce Support','Image Resize'],
    },
]

export default pricing;