// indexedDB.js
import { openDB } from 'idb';

const DB_NAME = 'Pixie';
const STORE_NAME = 'Collections';

export const initDB = async () => {
    const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
            db.createObjectStore(STORE_NAME);
        },
    });
    return db;
};

export const saveData = async (key, value) => {
    const db = await initDB();
    await db.put(STORE_NAME, value, key);
};

export const getData = async (key) => {
    const db = await initDB();
    return await db.get(STORE_NAME, key);
};
export const removeFromIndexedDB = async (key) => {
    const db = await initDB();
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const objectStore = transaction.objectStore(STORE_NAME);
    const request = objectStore.delete(key);
    
    return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve();
        request.onerror = () => reject('Error removing from IndexedDB');
    });
};

