/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import pretty from 'prettysize';
import { useQuery } from '../../lib/helpers';
import Container from '@material-ui/core/Container';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import ActionAlerts from '../../components/Alert/Alert.component';
import LinearProgress from '../../components/LinearProgress/LinearProgress.components';
import { useJobHandler } from '../../customHooks/jobHandlerHook';
import CircularProgressIndicator from '../../components/CircularProgress/CircularProgress.component';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import './OptimizeFields.styles.scss';
import { UserQuotaContext } from '../../contexts/UserQuotaContext';

function OptimizeFields({ onLoadUser }) {
  const [ids, setIds] = useState([]);
  const [optimized, setOptimized] = useState(0);
  const [totalFields, setTotalFields] = useState(0);
  const [optimizedStatus, setOptimizedStatus] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [errState, setErrState] = useState(false);
  const { setJobIds, jobsInfo, invalidIds, failedJobsArr, totalJobs } = useJobHandler();
  const { userQuota } = useContext(UserQuotaContext);
  const history = useHistory();
  // get url query
  const query = useQuery();

  const [message, setMessage] = useState({ msg: '', type: "" });
  const [results, setResults] = useState({ originalImagesSize: 0, optimizedImagesSize: 0, totalImagesOptimized: 0 });

  const progress = 100 / totalFields * optimized

  // Get Job Ids from URL and Create a array
  useEffect(() => {
    const queryJobs = query.get("jobs");
    if (queryJobs) {
      const jobsToArr = queryJobs.split(',');
      setJobIds(jobsToArr);
      setIds(jobsToArr);
      setMessage({ msg: 'Please Wait while we fetch and Optimize your collection fields', type: "info" })
    }
  }, [])

  // Get collection length and set it.
  useEffect(() => {
    if (totalFields > 0) {
      setMessage({ msg: `We are have started working on your CMS images. Based on the number of collections and images it can take some time. The app will keep running in the background you can go ahead and do what you were doing while we optimize your Webflow CMS images.`, type: "info" })
    }
  }, [totalFields])


  useEffect(() => {
    setOptimized(0);
    setResults({ originalImagesSize: 0, optimizedImagesSize: 0, totalImagesOptimized: 0 });
    checkAndUpdateOptimizedValues();
  }, [jobsInfo]);

  // Check if collections have been optimized
  useEffect(() => {
    // if (totalFields > 0 && optimized === totalFields) {
    //   setOptimizedStatus(true)
    //   setMessage({ msg: 'Successfully Optimized All Images, Thank You For Choosing Us', type: "success" })
    // }
    // if (optimized > 0 && !showResults) {
    //   setShowResults(true)
    // }
    const token = window.localStorage.getItem("token");
    if (token) onLoadUser(token)
    console.log(`total jobs ${totalJobs}`)
    console.log(`failed jobs ${failedJobsArr.length}`)

    if (totalJobs > 0 && (totalJobs === failedJobsArr.length)) {
      setShowResults(true)
      setMessage({ msg: 'failed to process all jobs', type: "error" })
    }
  }, [optimized, totalFields, totalJobs, failedJobsArr])

  //handle if there are invalid job ids
  useEffect(() => {
    if (invalidIds.length) {
      const doIdsExist = invalidIds.filter(id => !ids.includes(id));
      if (!doIdsExist.length) {
        setErrState(true);
      }
    }
  }, [invalidIds])

  useEffect(() => {
    if (errState) {
      setOptimized(0);
      setOptimizedStatus(false);
      setMessage({ msg: 'Invalid Job Ids, Please GoBack and add fields for Optimization', type: "error" })
    }
  }, [errState])


  const isBtnActive = optimizedStatus ? false : true;

  const addApiKeySite = () => {
    history.push(`/pixie/add-api-key`);
  }

  const checkAndUpdateOptimizedValues = () => {
    let hasTotalFields = 0;
    let optimizedNumOfFields = 0;
    Object.keys(jobsInfo).forEach(col => {
      jobsInfo[col].forEach(({ success, successData }) => {
        hasTotalFields = hasTotalFields + 1;
        if (success) {
          optimizedNumOfFields = optimizedNumOfFields + 1;
          const { optimizedImagesSize, originalImagesSize, totalImagesOptimized } = successData.optimizationResults;
          setResults(prevResults => {
            return {
              originalImagesSize: prevResults.originalImagesSize += originalImagesSize,
              optimizedImagesSize: prevResults.optimizedImagesSize += optimizedImagesSize,
              totalImagesOptimized: prevResults.totalImagesOptimized += totalImagesOptimized,
            }
          })
        }
      })
    })
    setTotalFields(hasTotalFields);
    setOptimized(optimizedNumOfFields);
  }
  const getMetaForCollection = (successData) => {
    let ci = 0;
    let si = 0;
    if (successData && successData.optimizationResults) {
      if (successData.optimizationResults.compressedImageURL instanceof Array) {
        ci = successData.optimizationResults.compressedImageURL.length;
      }
      if (successData.optimizationResults.skippedImages instanceof Array) {
        si = successData.optimizationResults.skippedImages.length;
      }
      return `Compressed Images: ${ci} | Skipped Images: ${si}`;
    } else return 'N/A';
  }

  const getIconByStatus = (status) => {
    switch (status) {
      case 'completed': return (<DoneIcon color="primary" />);
      case 'failed': return (<CloseIcon color="secondary" />);
      // case 'active': return (<CircularProgressIndicator size="20px" />);
      default: return (<CircularProgressIndicator size="20px" />);
      // default: return (<TimerIcon color="primary" />); 
    }
  }

  return (
    <div className="main-block">
      <Container maxWidth="xl">
        <div className="top-bottom-block">
          <div className="top-sec-full">
            <h2 className="heading-lite">
              Making your Webflow site faster...
            </h2>

            {
              message.msg ?
                (
                  <div className="progress-wrapper">
                    <LinearProgress num={progress} className="progress-bar" />
                    <ActionAlerts text={message.msg} type={message.type} />
                    {
                      !errState ?
                        <OptimizedState results={results} optimizedStatus={optimizedStatus} showResults={showResults} /> : null
                    }
                  </div>
                ) :
                null
            }

            {
              Object.keys(jobsInfo).map(ele => {
                return (
                  <div key={ele}>
                    <h3 className="collectionName">
                      {ele}
                    </h3>
                    {jobsInfo[ele].map(({ collectionFieldData, success, processing, state, id, successData, progress }) => (
                      <div className="field-info" key={collectionFieldData.id}>
                        <p className="fields" title={id + ' | ' + getMetaForCollection(successData)}>{collectionFieldData.name}{state === 'running' ? `: ${progress}%` : ''}</p>
                        {/* <p className="fields"> ({id}: {state}: {getMetaForCollection(successData)} | Progress: {progress}%)</p> */}
                        <span className="icons" title={`${state.toUpperCase()} | (${progress | 0}%)`}> {getIconByStatus(state)}</span>
                      </div>
                    )
                    )}
                  </div>
                )
              })
            }

          </div>
          <div className="nxt-prv">
          <div className="button-container">
            {message.type === "error" ? <CustomButton onClick={addApiKeySite} black={true} mr={true}>Add New Website</CustomButton> : null}
            <Link to="/pixie"><CustomButton disabled={isBtnActive} black={!isBtnActive} mr={true}>Done</CustomButton></Link>
            {userQuota > 0 ? null : <Link to="/pixie/pricing"><CustomButton black={true} disabled={isBtnActive}>Pricing</CustomButton></Link>}
          </div>
        </div>
        </div>
      </Container>
    </div>
  )
}

const OptimizedState = ({ results, optimizedStatus, showResults }) => {
  const { originalImagesSize, optimizedImagesSize, totalImagesOptimized } = results;
  return (
    <div className="results-component">
      <h4 className="block-title">{optimizedStatus ? 'Results' : 'Optimization in progress...'}</h4>
      {
        showResults ? (
          <div className="three-col-block">
            <div className="column">
              <h6 className="sm-title">Old Images Size</h6>
              <span className="numbers">{pretty(originalImagesSize)}</span>
            </div>
            <div className="column">
              <h6 className="sm-title">Optimized Size</h6>
              <span className="numbers">{pretty(optimizedImagesSize)}</span>
            </div>
            <div className="column">
              <h6 className="sm-title">Total Images Optimized</h6>
              <span className="numbers">{totalImagesOptimized.toString()}</span>
            </div>
          </div>
        ) : <CircularProgressIndicator />
      }
    </div>
  )
}


export default OptimizeFields;
