/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getTokenFromLS, parseJSON } from "../../lib/helpers";
import posthog from 'posthog-js'

const Oauth = ({ isSignedIn, setSignedIn }) => {
  const [authCode, setAuthCode] = useState("");
  const [message, setMessage] = useState("");
  const [verified, setVerified] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);

  const saveAuthTokenInSession = (token) => {
    window.localStorage.setItem('token', token);
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    if (query.get("code")) {
      setAuthCode(query.get("code"));
      if (getTokenFromLS()) {
        setMessage("Adding Your Site to Pixie. Please Wait");
        return;
      } else {
        setMessage("Verifying Credentials. Please Wait");
        return;
      }
    } else {
      setMessage("Unable to fetch auth code");
      setInvalidCode(true);
    }
  }, []);

  useEffect(() => {
    if (query.get("error")) {
      setVerified(true);
    }
  }, []);

  useEffect(() => {
    let interval;
    if (invalidCode) {
      interval = setTimeout(() => {
        setVerified(true);
      }, 3000);
    }
    return () => interval ? clearTimeout(interval) : null;
  }, [invalidCode])

  useEffect(() => {
    if (authCode) {
      sendAuthCodeToVerify();
    }
  }, [authCode]);

  useEffect(() => {
    if (verified) {
      history.push("/pixie");
    }
  }, [verified]);

  const fetchSites = async () => {
    await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/verify-auth-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: getTokenFromLS(),
      },
      body: JSON.stringify({
        authCode,
        clientId: process.env.REACT_APP_WEBFLOW_CLIENT_ID,
      }),
    })
      // .then(parseJSON)
      .then(async (data) => {
        if (data?.status === 401) {
          saveAuthTokenInSession("");
          setMessage("User Unauthorized. Please Login Again");
          setInvalidCode(true);
          setSignedIn(false);
          return;
        }

        data = await parseJSON(data);
        if (data.success) {
          posthog.capture('WebflowSiteAdded',{
            $set: {totalSitesAdded: data.totalSites}
          });
          setMessage("Successfully Added Sites");
          setVerified(true);
          return;
        }
        setMessage("Invalid Auth Code. Please try again.");
        setInvalidCode(true);
      })
      .catch((err) => {
        console.log(err);
        setMessage("Invalid Auth Code. Please try again.");
      });
  }

  const fetchSitesWithWebflow = async (token, authCode) => {
    await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/verify-auth-code-webflow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({
        secret: authCode,
        clientId: process.env.REACT_APP_WEBFLOW_CLIENT_ID,
      }),
    })
      .then(parseJSON)
      .then((data) => {
        if (data.success) {
          posthog.capture('WebflowSiteAdded',{
            $set: {totalSitesAdded: data.totalSites}
        });
        return data;
        }
        setMessage("Invalid Auth Code. Please try again.");
        setInvalidCode(true);
      })
      .catch((err) => {
        console.log(err);
        setMessage("Invalid Auth Code. Please try again.");
      });
  }

  const fetchUserCredentials = async () => {
    await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/verify-user-auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authCode,
        clientId: process.env.REACT_APP_WEBFLOW_CLIENT_ID,
      }),
    })
      .then(response => response.json())
      .then(async (data) => {
        console.log(data)
        if (data.success) {
          if(data.newUser){
            window.fbq('track', 'StartTrial', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'});
            posthog.capture('SignedUp',{
              $set: {email: data.email},
              email:data.email,
            });
          }else{
            posthog.capture('SignedIn',{
              $set: {email: data.email},
              email:data.email,
            });
          }
          await fetchSitesWithWebflow(data.token, data.userSecretKey);
          saveAuthTokenInSession(data.token)
          setMessage("Successfully Logged In");
          setSignedIn(true);
          setVerified(true);
          return;
        }
        saveAuthTokenInSession("");
        setMessage("Invalid Auth Code. Please try again.");
        setInvalidCode(true);
      })
      .catch((err) => {
        console.log(err);
        setMessage("Invalid Auth Code. Please try again.");
        setInvalidCode(true);
      });
  }

  const sendAuthCodeToVerify = () => {
    if (getTokenFromLS()) {
      fetchSites();
    } else if (!isSignedIn) {
      fetchUserCredentials();
    }
  };

  return (
    <div className="flex-center" style={{ backgroundColor: "#f6f6f6" }}>
      <h2 className="text-msg" style={{ marginBottom: "200px" ,marginLeft: "30px" }}>{message}</h2>
    </div>
  );
};

export default Oauth;
