import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    progressContainer: {
        marginLeft:"12.30vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative", 
      },
      processIndicator: {
        position: "absolute",
        top: "50%",
        left: "0.65vw",
        transform: "translateY(-50%)",
        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "3.21vh",
        color:"#272343",
        zIndex: 1,
      },
      linearProgress: {
        width: "46.35vw",
        height: "3.21vh",
        borderRadius: "1.6vh",
        backgroundColor: "#FFF",
        "& .MuiLinearProgress-bar": {
          borderRadius: "0.93vh",
          backgroundColor: "#FFD803",
        },
      },
});

export default function LinearDeterminate({ num,type }) {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {

        setProgress((oldProgress) => {
            if (oldProgress === 100) {
                return 0;
            }
            return num;
        });

    }, [num]);

    return (
        <div className={classes.progressContainer}>
        <div className={classes.processIndicator}>{type === 'convert'? 'Converting...' : 'Optimizing...'}</div>
        <LinearProgress
          variant="determinate"
          value={progress}
          className={classes.linearProgress}
        />
      </div>
    );
}
