import React, { useEffect, useState } from "react";
import NavMenu from "../NavMenu/NavMenu.component";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.styles.scss";
import logo from '../../images/logo.png';
import GrpPhoto from '../../images/Group 82.png'
import Modal from 'react-modal';
import cancel from '../../images/cancel.jpeg';
import CustomButton from "../CustomButton/CustomButton.component";
// import UserQuotaBox from "../UserQuotaBox/UserQuotaBox.component";
// import UserQuotaContextProvider from "../../contexts/UserQuotaContext";

const Navbar = ({ onRouteChange, isSignedIn, toggleModal, userImageCount, userPlanQuota,remainingCredits, userName, showCode,currentSubscription,dataLoaded }) => {
  const [isNewLinkAble, setIsNewLinkAble] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(true);
  const [allPlan, setAllPlan] = useState([]);
  const currentPath = window.location.pathname;
  let location = useLocation();
  const pixieProduct = location.pathname.includes("/pixie");
  const onCloseNewLink = () => {
    localStorage.setItem(
      "newLink_disable",
      JSON.stringify(true)
    );

    setIsNewLinkAble(false);
  }
  let imageOptimizationsDone;
  let creditsGiven;
  console.log(remainingCredits)
  if(remainingCredits && remainingCredits.length > 0){
    const prvAllowedImages = remainingCredits.map((allowedImages) => allowedImages.creditsLeft);
    const sumOfPrvAllowedImages = prvAllowedImages.reduce((acc, curr) => acc + curr, 0);
    const prvUsedImages = remainingCredits.map((allowedImages) => allowedImages.usedCredits);
    const sumOfprvUsedImages = prvUsedImages.reduce((acc, curr) => acc + curr, 0);
    imageOptimizationsDone = userImageCount + sumOfprvUsedImages;
    creditsGiven = allPlan.find(plan => plan.name === currentSubscription.planName)?.images_allowed + sumOfPrvAllowedImages;
  }else{
    imageOptimizationsDone = userImageCount;
    creditsGiven = allPlan.find(plan => plan.name === currentSubscription.planName)?.images_allowed
  }

  useEffect(() => {
    const isAble = localStorage.getItem("newLink_disable");
    isAble ? setIsNewLinkAble(false) : setIsNewLinkAble(true);
  }, []);
  useEffect(async()=>{
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}subscription/getAllPlans`);
      const data = await response.json();

      if (data?.success) {
          // data.plans.forEach((ele, index) => {
          //     if (ele.billing_period === 1) {
          //         setMonthlyPlan(monthlyPlan => [...monthlyPlan, ele]);
          //     } else if (ele.billing_period === 12) {
          //         setYearlyPlan(yearlyPlan => [...yearlyPlan, ele]);
          //     }
          // })
          setAllPlan(data.plans);

      }

      return 0;
  } catch (error) {
      console.log(error)
  }
  },[])
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(31, 31, 31, .9)',
    },
  };

  function closeModal() {
    localStorage.setItem(
      "newLink_disable",
      JSON.stringify(true)
    );

    setIsNewLinkAble(false);
    setIsOpen(false);
  }

  return (
    <div className="font-one">
      {/* {isNewLinkAble &&
        <div className="navbar-link">
          <div className="navbar-link-content">
            Pixie has moved to trypixie.io. Read more about it <a href="https://www.trypixie.io/blog-post/a-new-chapter-in-your-pixie-journey-enjoy-our-latest-enhancements" target="_blank">here</a>
          </div>
          <div className="navbar-link-close" onClick={onCloseNewLink}>X</div>
        </div>
      } */}
      {/* {
        isSignedIn ? isNewLinkAble &&
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Modal Content"
            className="ModalForLink"
          >
            <div>
              <div className='main-modalForLink'>
                <div className="main__head">
                  <div className="main__head__content">Exciting Enhancements on Pixie are Now Live!</div>
                  <div className="main__head__cancel">
                    <img onClick={closeModal} src={cancel} alt="load" />
                  </div>
                </div>

                <div className="main__content">
                  Hi {userName},
                  <br />
                  <br />
                  We've made some exciting changes to improve your Pixie experience!
                  <br />
                  <br />
                  From a simpler billing process to an updated pricing model and an enhanced user interface, we're dedicated to making Pixie better for you.
                  Learn more about these updates on our <a href="https://www.trypixie.io/blog-post/big-changes-ahead-pixies-transformation-to-enhance-your-experience" target="_blank">blog here.</a>
                  <br />
                  {showCode === true ?
                    (<><br />
                      As a token of our appreciation, we're offering you a FREE first month on your Pixie monthly subscription. Apply the COUPON CODE: <b>‘GRATITUDE23’</b> at checkout.
                      <br /></>)
                    : null
                  }
                  <br />
                  Thank you for choosing Pixie! Enjoy your enhanced Pixie experience.
                </div>
              </div>
            </div>
          </Modal>
          : null
      } */}
      <div className={` navbar-block ${isSignedIn ? "grey" : ""}`} id="main-navbar">
        <Link to="/" className="logo">
          <h2 className="logo-text">
            {/* <span className="pixie-text">Pixie</span> by little BIG Things */}
            <img className="pixie-logo" src={logo} alt="load" />
            {/* PIXIE  */}
          </h2>
        </Link>
        {dataLoaded && (
        !isSignedIn ? (
          <div className="nav-elements">
            <div className="nav-text">
              "The product is superfast, reliable and robust with great support"
            </div>
            <img src={GrpPhoto} alt="Image" className="nav-image" />
          </div>
  ) : 
    currentSubscription.isSubscribed ? (
      <div className="data-main">
        <div className="data-title">
        Optimisations Done
        </div>
        <div className="data">
          <span className="image-data">{imageOptimizationsDone}</span>/<span className="total-image">{creditsGiven}</span>
        </div>
      </div>
    ) : 
        currentPath != '/pixie/pricing' ? (
          <Link to="/pixie/pricing" className="logo">
          <CustomButton style={{ width: '99px', marginRight:'180px',marginTop:'26px'}} black={true} >Plans</CustomButton>
          </Link>
        ): (null
    
  )
        )}

      </div>
    </div>
    //  </RecoilRoot>
  );
};

export default Navbar;
