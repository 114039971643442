export const selectCases = valueName => {
  switch (valueName) {
    case 'WS' : {
      return {
        resizeStrategy: 'landscape',
        sizes: {
          width: 1920,
        }
      }     
    }
    case 'FLP' : {
      return {
        resizeStrategy: 'landscape',
        sizes: {
          width: 1440,
        }
      }     
    }
    case 'VH' : {
      return {
        resizeStrategy: 'portrait',
        sizes: {
          height: 800,
        }
      }     
    }
    case 'LQ' : {
      return {
        resizeStrategy: 'landscape',
        sizes: {
          width: 720,
        }
      }     
    }
    case 'PQ' : {
      return {
        resizeStrategy: 'portrait',
        sizes: {
          height: 400,
        }
      }     
    }
    case 'PRL' : {
      return {
        resizeStrategy: 'landscape',
        sizes: {
          width: 300,
        }
      }     
    }
    case 'PRML' : {
      return {
        resizeStrategy: 'landscape',
        sizes: {
          width: 200,
        }
      }     
    }
    case 'PRMP' : {
      return {
        resizeStrategy: 'portrait',
        sizes: {
          height: 200,
        }
      }     
    }
    case 'THP' : {
      return {
        resizeStrategy: 'portrait',
        sizes: {
          height: 100,
        }
      }     
    }
    case 'THL' : {
      return {
        resizeStrategy: 'landscape',
        sizes: {
          width: 100,
        }
      }     
    }
    case 'PRP' : {
      return {
        resizeStrategy: 'portrait',
        sizes: {
          height: 300,
        }
      }     
    }
    default: {
      return {
        resizeStrategy: null,
        sizes: null,
      }   
    }
  }
}

export const caseObj = [
  { id: 1, valueName: "NULL", optionName: "Don't Resize"},
  { id: 2, valueName: "WS", optionName : "White Screen: Width - 1920px"},
  { id: 3, valueName: "FLP", optionName : "Full Page: Width - 1440px"},
  { id: 4, valueName: "VH", optionName : "Vertical Half: Height - 800px"},
  { id: 5, valueName: "LQ", optionName : "Landscape Quarter: Width - 720px"},
  { id: 6, valueName: "PQ", optionName : "Portrait Quarter: Height - 400px"},
  { id: 7, valueName: "PRL", optionName : "Profile Large Landscape: Width - 300px"},
  { id: 8, valueName: "PRP", optionName : "Profile Large Portrait: Height - 300px"},
  { id: 9, valueName: "PRML", optionName : "Profile Medium Landscape: Width - 200px"},
  { id: 10, valueName: "PRMP", optionName : "Profile Medium Portrait: Height - 200px"},
  { id: 11, valueName: "THP", optionName : "Thumbnail: Height - 100px"},
  { id: 12, valueName: "THL", optionName : "Thumbnail: Width - 100px"},
]
